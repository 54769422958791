<template>
  <div>
<!--    <el-card class="base-info" shadow="hover">-->
<!--      <div class="title">{{ $t('alter.basic_info') }}</div>-->
<!--      <div class="base-info-body">-->
<!--        <div class="left">-->
<!--          <img src="../../assets/img/safe/pc_phone.svg" alt="">-->
<!--          <div class="title-sub">{{ $t('alter.phone_number') }}</div>-->
<!--          <div>-->
<!--            <div>{{ $t('alter.phone_num_tip') }}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="right">-->
<!--          <span style="margin-right: 20px;font-size: 14px;font-weight: 400;color: #000613;" v-if="userBoundPhone">{{userInfo.mobile.slice(0,3)}} <span>****</span> {{userInfo.mobile.slice(7)}}</span>-->
<!--          <span style="font-size: 14px;font-weight: 400;color: #AB54DB;cursor: pointer" @click="changePhoneDialogVisible=true"><span v-if="userBoundPhone">{{ $t('alter.change') }}</span><span v-else>{{ $t('alter.binded') }}</span></span>-->
<!--          <span v-if="userBoundPhone" style="font-size: 14px;margin-left: 20px;font-weight: 400;color: #AB54DB;cursor: pointer"><span @click="unbindPhoneDialogVisible=true" >{{ $t('alter.unbind') }}</span></span>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="base-info-body">-->
<!--        <div class="left">-->
<!--          <img src="../../assets/img/safe/pc_email.svg" alt="">-->
<!--          <div class="title-sub">{{ $t('alter.bind_email_address') }}</div>-->
<!--          <div>-->
<!--            <div>{{ $t('alter.email_tip') }}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="right">-->
<!--          <span style="margin-right: 40px;font-size: 14px;font-weight: 400;color: #000613;" v-if="userBoundEmail">{{userInfo.username.slice(0,3)}} <span>****</span> {{userInfo.username.slice(7)}}</span>-->
<!--          <span style="font-size: 14px;font-weight: 400;color: #AB54DB;cursor: pointer" @click="bindEmailDialogVisible=true"><span v-if="userBoundEmail">{{ $t('alter.change') }}</span><span v-else>{{ $t('alter.binded') }}</span></span>-->
<!--          <span v-if="userBoundEmail" style="font-size: 14px;margin-left: 20px;font-weight: 400;color: #AB54DB;cursor: pointer"><span @click="unbindEmailDialogVisible=true">{{ $t('alter.unbind') }}</span></span>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="base-info-body">-->
<!--        <div class="left">-->
<!--          <img src="../../assets/img/safe/pc_password.svg" alt="">-->
<!--          <div class="title-sub">{{ $t('alter.login_pwd') }}</div>-->
<!--          <div>-->
<!--            <div>{{ $t('alter.pwd_tip') }}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="right">-->
<!--          <span style="font-size: 14px;font-weight: 400;color: #AB54DB;cursor: pointer" @click="changePasswordDialogVisible=true">{{ $t('alter.alter') }}</span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </el-card>-->
    <!--    api信息-->
    <el-card class="base-info api" shadow="hover">
      <div class="title">{{ $t('alter.bing_exchange') }}</div>
      <div class="base-info-body api-body">
        <div class="left">
          <div>
            <img style="vertical-align: middle;width: 24px;height: 24px" src="../../assets/img/robot/bian_click_icon.svg" alt="">
            <div class="title-sub" style="margin-left: 0px;vertical-align: middle">Binance API</div>
            <div v-if="bian_Item.bind_api == 1" style="display: inline-block; margin-left: 20px">
              <span :class="bian_Item.is_exclusive == 1?'exclusive_bg' :'exclusive_no_bg'" style="">专</span>
              <span>{{bian_Item.is_exclusive == 1?'专属链接用户': '非专属链接用户'}}</span>
            </div>
          </div>
          <div >
            <div v-if="apiInfo.binance_api_key!==null&&apiInfo.binance_api_key!==''"><span>API KEY: </span><span><span>{{apiInfo.binance_api_key}}</span></span></div>
            <div v-if="apiInfo.binance_api_key!==null&&apiInfo.binance_api_key!==''"><span>API Secret: </span><span>{{apiInfo.binance_api_secret}}</span></div>
            <div v-else  style="font-size: 14px;font-weight: 400;color: #72777E;"><a style="color:#AB54DB;" target="_blank" :href="$registered_address.binance_address()">Binance{{ $t('alter.registered_address') }}</a></div>
          </div>
        </div>
        <div class="right">
          <span v-if="apiInfo.binance_api_key!==null&&apiInfo.binance_api_key!==''&&bian_Item.sort == 1&&apiList.length>1" style="font-size: 14px;font-weight: 400;color: #72777E;margin-right:20px;cursor: auto">{{ $t('alter.priority_display') }}</span>
          <span v-if="apiInfo.binance_api_key!==null&&apiInfo.binance_api_key!==''&&bian_Item.sort != 1&&apiList.length>1" style="font-size: 14px;font-weight: 400;color: #AB54DB;margin-right:20px;cursor: pointer" @click="setNo1(1)">{{ $t('alter.set_priority') }}</span>
          <span v-if="apiInfo.binance_api_key!==null&&apiInfo.binance_api_key!==''" style="font-size: 14px;font-weight: 400;color: #AB54DB;cursor: pointer" @click="changeApiDialogVisible=true">{{ $t('alter.alter') }}</span>
          <span v-else style="font-size: 14px;font-weight: 400;color: #AB54DB;cursor: pointer" @click="changeApiDialogVisible=true">{{ $t('alter.binded') }}</span>
          <span v-if="apiInfo.binance_api_key" style="font-size: 14px;font-weight: 400;color: #AB54DB;cursor: pointer;margin-left: 20px" @click="beforeClearApi(bian_Item, 1)">{{ $t('alter.unbind') }}API</span>
        </div>
      </div>
      <div class="base-info-body api-body">
        <div class="left">
          <div>
            <img style="vertical-align: middle;width: 24px;height: 24px;" src="../../assets/img/robot/okx_new.svg" alt="">
            <div style="vertical-align: middle; margin-left: 0px" class="title-sub">Okx API</div>
            <div v-if="okex_Item.bind_api == 1" style="display: inline-block; margin-left: 20px">
              <span :class="okex_Item.is_exclusive == 1?'exclusive_bg' :'exclusive_no_bg'" style="">专</span>
              <span>{{okex_Item.is_exclusive == 1?'专属链接用户': '非专属链接用户'}}</span>
            </div>
          </div>
          <div >
            <div v-if="okApiInfo.okex_api_key!==null&&okApiInfo.okex_api_key!==''"><span>API KEY: </span><span><span>{{okApiInfo.okex_api_key}}</span></span></div>
            <div v-if="okApiInfo.okex_api_key!==null&&okApiInfo.okex_api_key!==''"><span>API Secret: </span><span>{{okApiInfo.okex_api_secret}}</span></div>
            <div v-if="okApiInfo.okex_api_key!==null&&okApiInfo.okex_api_key!==''"style="margin-top: 6px"><span>API Passphrase: </span><span>{{okApiInfo.okex_passphrase}}</span></div>
            <div v-else style="font-size: 14px;font-weight: 400;color: #72777E;"><a style="color:#AB54DB;" target="_blank" :href="$registered_address.okx_address()">OKX{{ $t('alter.registered_address') }}：{{ $registered_address.okx_address() }}</a>{{ $t('alter.register_address_tip') }}<span style="color: #E3B936;cursor: pointer;border-bottom: #E3B936 1px solid;line-height: 14px;width: fit-content;" @click="copy()">{{ $t('alter.copy') }}</span></div>
          </div>
        </div>
        <div class="right">
          <span v-if="okApiInfo.okex_api_key!==null&&okApiInfo.okex_api_key!==''&&okex_Item.sort == 1&&apiList.length>1" style="font-size: 14px;font-weight: 400;color: #72777E;margin-right:20px;cursor: auto">{{ $t('alter.priority_display') }}</span>
          <span v-if="okApiInfo.okex_api_key!==null&&okApiInfo.okex_api_key!==''&&okex_Item.sort != 1&&apiList.length>1" style="font-size: 14px;font-weight: 400;color: #AB54DB;margin-right:20px;cursor: pointer" @click="setNo1(2)">{{ $t('alter.set_priority') }}</span>
          <span v-if="okApiInfo.okex_api_key!==null&&okApiInfo.okex_api_key!==''" style="font-size: 14px;font-weight: 400;color: #AB54DB;cursor: pointer" @click="changeAndBindOkApi()">{{ $t('alter.alter') }}</span>
          <span v-else style="font-size: 14px;font-weight: 400;color: #AB54DB;cursor: pointer" @click="changeAndBindOkApi()">{{ $t('alter.binded') }}</span>
          <span v-if="okApiInfo.okex_api_key" style="font-size: 14px;font-weight: 400;color: #AB54DB;cursor: pointer;margin-left: 20px" @click="beforeClearApi(okex_Item, 2)">{{ $t('alter.unbind') }}API</span>
        </div>
      </div>
    </el-card>
    <!--    更换手机的对话框-->
    <el-dialog
        :title="userBoundPhone ? this.$t('alter.change_login_phone'): this.$t('alter.bind_login_phone')"
        :visible.sync="changePhoneDialogVisible"
        :close-on-click-modal="false"
        @close="handleClosePhoneDialog"
        width="510px">
      <el-form  :model="changePhoneForm" :rules="changePhoneFormRules" ref="changePhoneFormRef">
        <!--        当用户已经绑定过手机号-->
        <div v-if="userBoundPhone">
          <el-form-item prop="oldPhone">
            <div>{{ $t('alter.your_phone') }}</div>
            <el-input disabled v-model="changePhoneForm.oldPhone"></el-input>
          </el-form-item>
          <el-form-item prop="old_auth_code">
            <div>{{ $t('alter.old_phone_code') }}</div>
            <el-input v-model="changePhoneForm.old_auth_code"></el-input>
            <div class="auth-code">
              <div v-if="isOldPhoneSent" @click="sendAuthCode('oldPhone')" style="font-size: 14px;font-weight: 400;color: #AB54DB;">{{ $t('alter.send_code') }}</div>
              <div v-else><span>{{oldPhoneSeconds}}</span><span>{{ $t('alter.resend_code') }}</span></div>
            </div>
          </el-form-item>
          <el-form-item prop="newPhone">
            <div>{{ $t('alter.new_phone') }}</div>
            <el-input v-model="changePhoneForm.newPhone"></el-input>
          </el-form-item>
          <el-form-item prop="auth_code">
            <div>{{ $t('alter.new_phone_code') }}</div>
            <el-input v-model="changePhoneForm.auth_code"></el-input>
            <div class="auth-code">
              <div v-if="isPhoneSent" @click="sendAuthCode('phone')" style="font-size: 14px;font-weight: 400;color: #AB54DB;">{{ $t('alter.send_code') }}</div>
              <div v-else><span>{{phoneSeconds}}</span><span>{{ $t('alter.resend_code') }}</span></div>
            </div>
          </el-form-item>
        </div>
        <!--        当用户首次绑定手机号-->
        <div v-else>
          <el-form-item prop="newPhone">
            <div>{{ $t('alter.your_phone') }}</div>
            <el-input v-model="changePhoneForm.newPhone"></el-input>
          </el-form-item>
          <el-form-item prop="auth_code">
            <div>{{ $t('alter.phone_code') }}</div>
            <el-input v-model="changePhoneForm.auth_code"></el-input>
            <div class="auth-code">
              <div v-if="isPhoneSent" @click="sendAuthCode('phone')" style="font-size: 14px;font-weight: 400;color: #AB54DB;">{{ $t('alter.send_code') }}</div>
              <div v-else><span>{{phoneSeconds}}</span><span>{{ $t('alter.resend_code') }}</span></div>
            </div>
          </el-form-item>
        </div>

      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="changePhoneDialogVisible = false">{{ $t('alter.cancel') }}</el-button>
    <el-button type="primary" @click="handleChangePhone">{{ $t('alter.sure') }}</el-button>
  </span>
    </el-dialog>
    <!--    绑定邮箱的对话框-->
    <el-dialog
        :title="userBoundEmail ?$t('alter.change_email'):$t('alter.bind_email')"
        :visible.sync="bindEmailDialogVisible"
        :close-on-click-modal="false"
        @close="handleCloseEmailDialog"
        width="510px">
      <el-form  :model="bindEmailForm" :rules="bindEmailFormRules" ref="bindEmailFormRef">
        <!--        当用户已经绑定过邮箱需要修改邮箱的时候-->
        <div v-if="userBoundEmail">
          <el-form-item prop="oldEmail">
            <div>{{ $t('alter.email_address') }}</div>
            <el-input disabled v-model="bindEmailForm.oldEmail"></el-input>
          </el-form-item>
          <el-form-item prop="old_auth_code">
            <div>{{ $t('alter.old_email_code') }}</div>
            <el-input v-model="bindEmailForm.old_auth_code"></el-input>
            <div class="auth-code">
              <div v-if="isOldEmailSent" @click="sendAuthCode('oldEmail')" style="font-size: 14px;font-weight: 400;color: #AB54DB;">{{ $t('alter.send_code') }}</div>
              <div v-else><span>{{oldEmailSeconds}}</span><span>{{ $t('alter.resend_code') }}</span></div>
            </div>
          </el-form-item>
          <el-form-item prop="email">
            <div>{{ $t('alter.new_email_address') }}</div>
            <el-input v-model="bindEmailForm.email"></el-input>
          </el-form-item>
          <el-form-item prop="auth_code">
            <div>{{ $t('alter.new_email_code') }}</div>
            <el-input v-model="bindEmailForm.auth_code"></el-input>
            <div class="auth-code">
              <div v-if="isEmailSent" @click="sendAuthCode('email')" style="font-size: 14px;font-weight: 400;color: #AB54DB;">{{ $t('alter.send_code') }}</div>
              <div v-else><span>{{emailSeconds}}</span><span>{{ $t('alter.resend_code') }}</span></div>
            </div>
          </el-form-item>
        </div>
        <!--        当用户第一次绑定邮箱-->
        <div v-else>
          <el-form-item prop="email">
            <div>{{ $t('alter.email_address') }}</div>
            <el-input v-model="bindEmailForm.email"></el-input>
          </el-form-item>
          <el-form-item prop="auth_code">
            <div>{{ $t('alter.email_code') }}</div>
            <el-input v-model="bindEmailForm.auth_code"></el-input>
            <div class="auth-code">
              <div v-if="isEmailSent" @click="sendAuthCode('email')" style="font-size: 14px;font-weight: 400;color: #AB54DB;">{{ $t('alter.send_code') }}</div>
              <div v-else><span>{{emailSeconds}}</span><span>{{ $t('alter.resend_code') }}</span></div>
            </div>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="bindEmailDialogVisible = false">{{ $t('alter.cancel') }}</el-button>
    <el-button type="primary" @click="handleChangeEmail">{{ $t('alter.sure') }}</el-button>
  </span>
    </el-dialog>
    <!--    修改登录密码的对话框-->
    <el-dialog
        :title="$t('alter.change_pwd')"
        :visible.sync="changePasswordDialogVisible"
        :close-on-click-modal="false"
        width="510px">
      <el-form  :model="changePasswordForm" :rules="changePasswordFormRules" ref="changePasswordFormRef">
        <el-form-item prop="old_password">
          <div>{{ $t('alter.old_pwd') }}</div>
          <el-input v-model="changePasswordForm.old_password"></el-input>
        </el-form-item>
        <el-form-item prop="new_password">
          <div>{{ $t('alter.new_pwd') }}</div>
          <el-input v-model="changePasswordForm.new_password" autocomplete="new-password" show-password></el-input>
        </el-form-item>
        <el-form-item prop="confirm_password">
          <div>{{ $t('alter.confirm_pwd') }}</div>
          <el-input v-model="changePasswordForm.confirm_password" show-password></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="changePasswordDialogVisible = false">{{ $t('alter.cancel') }}</el-button>
    <el-button type="primary" @click="handleChangePassword">{{ $t('alter.sure') }}</el-button>
  </span>
    </el-dialog>
    <!--    修改API信息-->
    <el-dialog
        :title="$t('alter.change_api_info')+'asdasdasd'"
        :visible.sync="changeApiDialogVisible"
        :close-on-click-modal="false"
        @close="closebianDialog()"
        width="510px">
      <el-form  :model="changeApiForm" :rules="changeApiFormRules" ref="changeApiFormRef">
        <el-form-item prop="key">
          <div>API KEY</div>
          <el-input v-model="changeApiForm.key"></el-input>
        </el-form-item>
        <el-form-item prop="secret">
          <div>API Secret</div>
          <el-input type="password" auto-complete="new-password" v-model="changeApiForm.secret" show-password></el-input>
        </el-form-item>
        <el-form-item prop="auth_code">
          <div>{{ $t('alter.email_code') }}（{{userInfo.username}}）</div>
          <el-input v-model="changeApiForm.auth_code"></el-input>
          <div class="auth-code">
            <div v-if="isPhoneApiSent" @click="sendAuthCode('phoneApi')" style="font-size: 14px;font-weight: 400;color: #AB54DB;">{{ $t('alter.send_code') }}</div>
            <div v-else><span>{{phoneApiSeconds}}</span><span>{{ $t('alter.resend_code') }}</span></div>
          </div>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="changeApiDialogVisible = false">{{ $t('alter.cancel') }}</el-button>
    <el-button type="primary" @click="handleChangeApi">{{ $t('alter.sure') }}</el-button>
  </span>
    </el-dialog>
    <!--    修改ok_API信息-->
    <el-dialog
        :title="$t('alter.change_api_info')"
        :visible.sync="changeOkApiDialogVisible"
        :close-on-click-modal="false"
        @close="closeOkxDialog()"
        width="510px">
      <el-form :model="changeOkApiForm" :rules="changeOkApiFormRules" ref="changeOkApiFormRef">
        <el-form-item prop="key">
          <div>API KEY</div>
          <el-input v-model="changeOkApiForm.key"></el-input>
        </el-form-item>
        <el-form-item prop="secret">
          <div>API Secret</div>
          <el-input type="password" auto-complete="new-password" v-model="changeOkApiForm.secret" show-password></el-input>
        </el-form-item>
        <el-form-item prop="okexPassphrase">
          <div>{{ $t('alter.passphrase') }}</div>
          <el-input type="password" auto-complete="new-password" v-model="changeOkApiForm.okexPassphrase" show-password></el-input>
        </el-form-item>
        <el-form-item prop="auth_code">
          <div>{{ $t('alter.verify_message') }}（{{userInfo.username?userInfo.username:userInfo.mobile}}）</div>
          <el-input v-model="changeOkApiForm.auth_code"></el-input>
          <div class="auth-code">
            <div v-if="isPhoneApiSent" @click="sendAuthCode('phoneApi')" style="font-size: 14px;font-weight: 400;color: #AB54DB;">{{ $t('alter.send_code') }}</div>
            <div v-else><span>{{phoneApiSeconds}}</span><span>{{ $t('alter.resend_code') }}</span></div>
          </div>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="changeOkApiDialogVisible = false">{{ $t('alter.cancel') }}</el-button>
    <el-button type="primary" @click="handleChangeOkApi">{{ $t('alter.sure') }}</el-button>
  </span>
    </el-dialog>

<!--    解绑手机-->
    <el-dialog
        :title="$t('alter.unbind_phone')"
        :visible.sync="unbindPhoneDialogVisible"
        :close-on-click-modal="false"
        width="510px">
      <el-form  :model="unPhoneForm" :rules="unPhoneFormRules" ref="unPhoneFormRef">
          <el-form-item prop="">
            <div>{{ $t('alter.your_phone') }}</div>
            <el-input disabled v-model="userInfo.mobile"></el-input>
          </el-form-item>
          <el-form-item prop="auth_code">
            <div>{{ $t('alter.phone_code') }}</div>
            <el-input v-model="unPhoneForm.auth_code"></el-input>
            <div class="auth-code">
              <div v-if="isUnPhoneSent" @click="sendAuthCode('unPhone')" style="font-size: 14px;font-weight: 400;color: #AB54DB;">{{ $t('alter.send_code') }}</div>
              <div v-else><span>{{unPhoneSeconds}}</span><span>{{ $t('alter.resend_code') }}</span></div>
            </div>
          </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="unbindPhoneDialogVisible = false">{{ $t('alter.cancel') }}</el-button>
    <el-button type="primary" @click="handleUnPhone(2)">{{ $t('alter.sure') }}</el-button>
  </span>
    </el-dialog>

    <!--    解绑郵箱-->
    <el-dialog
        :title="$t('alter.unbind_email')"
        :visible.sync="unbindEmailDialogVisible"
        :close-on-click-modal="false"
        width="510px">
      <el-form  :model="unEmailForm" :rules="unEmailFormRules" ref="unEmailFormRef">
        <el-form-item prop="">
          <div>{{ $t('alter.email_address') }}</div>
          <el-input disabled v-model="userInfo.username"></el-input>
        </el-form-item>
        <el-form-item prop="auth_code">
          <div>{{ $t('alter.old_email_code') }}</div>
          <el-input v-model="unEmailForm.auth_code"></el-input>
          <div class="auth-code">
            <div v-if="isUnEmailSent" @click="sendAuthCode('unEmail')" style="font-size: 14px;font-weight: 400;color: #AB54DB;">{{ $t('alter.send_code') }}</div>
            <div v-else><span>{{unEmailSeconds}}</span><span>{{ $t('alter.resend_code') }}</span></div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="unbindEmailDialogVisible = false">{{ $t('alter.cancel') }}</el-button>
    <el-button type="primary" @click="handleUnPhone(1)">{{ $t('alter.sure') }}</el-button>
  </span>
    </el-dialog>


    <el-drawer
        v-if="!isMobile"
        class="exclusive_pc"
        :visible.sync="isExclusive"
        :direction="direction"
        :modal="false">
      <div slot="title">
        <img style="width: 24px; height: 24px;vertical-align: middle" src="../../assets/img/user/tips.png">
        <span style="font-weight: 500;font-size: 16px;line-height: 19px;color: #464255;vertical-align: middle; margin-left: 5px">专属用户限时98%折扣！</span>
      </div>
      <div class="exclusive_tip_content">
        <span style="font-weight: 500;color: #464255;">OKX专属用户：通过OKX专属链接（</span>
        <a target="_blank" style="font-weight: 500;" :href="$registered_address.okx_address()">{{$registered_address.okx_address()}}</a>
        <span style="font-weight: 500;color: #464255;">）或邀请码（UPLUS3）注册的交易所会员。</span>
      </div>
      <div class="exclusive_tip_content" style="margin-top: 8px">
        <span style="font-weight: 500;color: #464255;">币安专属用户：申请币安API，通过UPlus平台校验并绑定成功的用户。</span>
      </div>
      <el-button type="primary" style="height: 30px;width: 100px;text-align: center;padding: unset; float: right; margin-top: 6px" @click="isExclusive = false">{{ $t('robot.i_know') }}</el-button>
    </el-drawer>

    <el-dialog
        v-if="isMobile"
        class="exclusive_tip"
        :visible.sync="isExclusiveMb"
        :close-on-click-modal="false"
        :modal="exclusiveModal"
        width="380px">
      <div slot="title">
        <img style="width: 24px; height: 24px;vertical-align: middle" src="../../assets/img/user/tips.png">
        <span style="font-weight: 500;font-size: 16px;line-height: 19px;color: #464255;vertical-align: middle; margin-left: 5px">专属用户限时98%折扣！</span>
      </div>
      <div class="exclusive_tip_content">
        <span style="font-weight: 500;color: #464255;">OKX专属用户：通过OKX专属链接（</span>
        <a target="_blank" style="font-weight: 500;" :href="$registered_address.okx_address()">{{$registered_address.okx_address()}}</a>
        <span style="font-weight: 500;color: #464255;">）或邀请码（UPLUS3）注册的交易所会员。</span>
      </div>
      <div class="exclusive_tip_content" style="margin-top: 8px">
        <span style="font-weight: 500;color: #464255;">币安专属用户：申请币安API，通过UPlus平台校验并绑定成功的用户。</span>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" style="height: 30px;width: 100px;text-align: center;padding: unset;" @click="isExclusiveMb = false">{{ $t('robot.i_know') }}</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import {
  bindPhoneOrEmail, changeOkApi,
  changePassword,
  changeUserApi,
  changeUserInfo, ClearApi,
  getAuthCode, getOkApiInfo,
  getUserApiInfo, updateRobotSort
} from "../../server/auth";
import {
  checkEmail,
  checkEmailGeneral,
  checkEmailOrIsExist, checkIsEmoji, checkMobile,
  checkMobileGeneral,
  checkMobileOrIsExist,
  checkNewPassword, checkPassword
} from "../../utils/validate";
import {getUserInfo} from "../../server/rebate";
import {delSj, getRobotList} from "../../server/robot";
import {userUnEmailOrPhone} from "../../server/user";

export default {
  name: "Safe",
  data(){
    return{
      //更换手机号
      changePhoneDialogVisible:false,
      changePhoneForm:{
        oldPhone:'',
        old_auth_code:'',
        newPhone:'',
        auth_code:''
      },
      changePhoneFormRules:{
        newPhone:[{ required: true, message: this.$t('alter.input_new_phone'), trigger: 'blur' },
          {validator:checkMobileOrIsExist,trigger: 'blur'}],
        auth_code:[{ required: true, message: this.$t('alter.input_code'), trigger: 'blur' }],
        oldPhone:[{ required: true, message: this.$t('alter.input_old_phone'), trigger: 'blur' },
          {validator:checkMobile,trigger: 'blur'}],
        old_auth_code:[{ required: true, message: this.$t('alter.input_code'), trigger: 'blur' }],
      },
      //绑定邮箱
      bindEmailDialogVisible:false,
      bindEmailForm:{
        oldEmail:'',
        old_auth_code:'',
        email:'',
        auth_code:''
      },
      bindEmailFormRules:{
        oldEmail:[{ required: true, message: this.$t('alter.input_old_email_address'), trigger: 'blur' },
          {validator:checkEmail,trigger: 'blur'}],
        old_auth_code:[{ required: true, message: this.$t('alter.input_old_email_code'), trigger: 'blur' }],
        email:[{ required: true, message: this.$t('alter.input_new_email_address'), trigger: 'blur' },
          {validator:checkEmailOrIsExist,trigger: 'blur'}],
        auth_code:[{ required: true, message: this.$t('alter.input_new_email_code'), trigger: 'blur' }],
      },
      //  修改登录密码
      changePasswordDialogVisible:false,
      changePasswordForm:{
        old_password:'',
        new_password:'',
        confirm_password:''
      },
      changePasswordFormRules:{
        old_password:[{ required: true, message: this.$t('alter.input_old_pwd'), trigger: 'blur' }],
        new_password:[{ required: true, message: this.$t('alter.input_new_pwd'), trigger: 'blur' }, {validator:checkPassword,trigger: 'blur'}],
        confirm_password: [{ required: true, message: this.$t('alter.input_new_pwd_agin'), trigger: 'blur' }, {validator:checkPassword,trigger: 'blur'}]
      },
      //  修改API信息
      changeApiDialogVisible:false,
      changeOkApiDialogVisible: false,
      changeApiForm:{
        key:'',
        secret:'',
        auth_code:''
      },
      changeOkApiForm:{
        key:'',
        secret:'',
        okexPassphrase:'',
        auth_code:''
      },
      changeApiFormRules:{
        key:[{ required: true, message: this.$t('alter.please_enter')+'API KEY', trigger: 'blur' }],
        secret:[{ required: true, message: this.$t('alter.please_enter')+'API Secret', trigger: 'blur' }],
        auth_code:[{ required: true, message: this.$t('alter.input_code'), trigger: 'blur' }],
      },
      changeOkApiFormRules:{
        key:[{ required: true, message: this.$t('alter.please_enter')+'API KEY', trigger: 'blur' }],
        secret:[{ required: true, message: this.$t('alter.please_enter')+'API Secret', trigger: 'blur' }],
        okexPassphrase:[{ required: true, message: this.$t('alter.please_enter')+'API Passphrase', trigger: 'blur' }],
        auth_code:[{ required: true, message: this.$t('alter.input_code'), trigger: 'blur' }],
      },

      //  发送验证码的功能
      isPhoneSent:true,
      phoneSeconds:60,
      isEmailSent:true,
      emailSeconds:60,
      isOldPhoneSent:true,
      oldPhoneSeconds:60,
      isOldEmailSent:true,
      oldEmailSeconds:60,
      isPhoneApiSent:true,
      phoneApiSeconds:60,

      isUnEmailSent:true,
      unEmailSeconds:60,
      isUnPhoneSent:true,
      unPhoneSeconds:60,
      //解綁手機
      unbindPhoneDialogVisible:false,
      unPhoneForm:{
        auth_code:''
      },
      unPhoneFormRules:{
        auth_code:[{ required: true, message: this.$t('alter.input_phone_code'), trigger: 'blur' }],
      },
      //解綁郵箱
      unbindEmailDialogVisible:false,
      unEmailForm:{
        auth_code:''
      },
      unEmailFormRules:{
        auth_code:[{ required: true, message: this.$t('alter.input_email_code'), trigger: 'blur' }],
      },

      //  用户信息
      userInfo:{},
      //  用户api信息
      apiInfo:'',
      okApiInfo:'',
      //  用户是否绑定过手机号
      userBoundPhone:false,
      //  用户是否绑定过邮箱
      userBoundEmail:false,

      isMobile: false,
      apiList:[],
      bian_Item:{},
      okex_Item:{},
      isExclusive: true,
      isExclusiveMb: true,
      exclusiveModal: false,
      direction: 'rtl'
    }
  },
  created() {
    if (this.$util._isMobile()){
      this.isMobile = true
    }
    if (this.isMobile){
      this.exclusiveModal = true
    } else {
      this.exclusiveModal = false
    }
    this.getUserInfo()
    this.getApiInfo()
    this.getOkApiInfo()
    this.getRobotList()
  },
  mounted() {
    if (this.$store.state.isBindEmail) this.bindEmailDialogVisible=true
    this.$store.state.isBindEmail=false
  },
  methods:{
    getUserInfo(){
      this.userInfo=JSON.parse(localStorage.getItem('userInfo'))
      this.userInfo.mobile!==''&&this.userInfo.mobile!==null?this.userBoundPhone=true:this.userBoundPhone=false
      this.userInfo.username!==''&&this.userInfo.username!==null?this.userBoundEmail=true:this.userBoundEmail=false
      this.changePhoneForm.oldPhone=this.userInfo.mobile
      this.bindEmailForm.oldEmail=this.userInfo.username
    },
    async sendAuthCode(kind){
      if (kind==='phone'){
        if (!checkMobileGeneral(this.changePhoneForm.newPhone)) return this.$message.error(this.$t('alter.sure_phone_format'))
        this.isPhoneSent=false
        const timer=setInterval(()=>{
          this.phoneSeconds--
          if (this.phoneSeconds===0){
            this.phoneSeconds=60
            this.isPhoneSent=true
            clearInterval(timer)
          }
        },1000)
        const res=await getAuthCode(3,2,this.changePhoneForm.newPhone)
        if (!res.success) return this.$message.warning(res.message)
        if (res.success) this.$message.success(this.$t('alter.code_sended_phone'))
      }
      else if (kind==='email'){
        if (!checkEmailGeneral(this.bindEmailForm.email)) return this.$message.error(this.$t('alter.sure_new_email_format'))
        this.isEmailSent=false
        const timer=setInterval(()=>{
          this.emailSeconds--
          if (this.emailSeconds===0){
            this.emailSeconds=60
            this.isEmailSent=true
            clearInterval(timer)
          }
        },1000)
        const res=await getAuthCode(3,1,this.bindEmailForm.email)
        if (!res.success) return this.$message.warning(res.message)
        if (res.success) this.$message.success(this.$t('alter.code_sended_email'))
      }
      if (kind==='oldPhone'){
        if (!checkMobileGeneral(this.changePhoneForm.oldPhone)) return this.$message.error(this.$t('alter.sure_phone_format'))
        this.isOldPhoneSent=false
        const timer=setInterval(()=>{
          this.oldPhoneSeconds--
          if (this.oldPhoneSeconds===0){
            this.oldPhoneSeconds=60
            this.isOldPhoneSent=true
            clearInterval(timer)
          }
        },1000)
        const res=await getAuthCode(3,2,this.changePhoneForm.oldPhone)
        if (!res.success) return this.$message.warning(res.message)
        if (res.success) this.$message.success(this.$t('alter.code_sended_phone'))
      }
      if (kind==='oldEmail'){
        if (!checkEmailGeneral(this.bindEmailForm.oldEmail)) return this.$message.error(this.$t('alter.sure_old_email_format'))
        this.isOldEmailSent=false
        const timer=setInterval(()=>{
          this.oldEmailSeconds--
          if (this.oldEmailSeconds===0){
            this.oldEmailSeconds=60
            this.isOldEmailSent=true
            clearInterval(timer)
          }
        },1000)
        const res=await getAuthCode(3,1,this.bindEmailForm.oldEmail)
        if (!res.success) return this.$message.warning(res.message)
        if (res.success) this.$message.success(this.$t('alter.code_sended_phone'))
      }
      if (kind==='phoneApi'){
        // if (!this.userBoundEmail) return this.$message.warning(this.$t('alter.bind_email_first'))
        this.isPhoneApiSent=false
        const timer=setInterval(()=>{
          this.phoneApiSeconds--
          if (this.phoneApiSeconds===0){
            this.phoneApiSeconds=60
            this.isPhoneApiSent=true
            clearInterval(timer)
          }
        },1000)
        var sendType = 1
        var address = ''
        if(this.userInfo.username){
          sendType = 1
          address = this.userInfo.username
        } else {
          sendType = 2
          address = this.userInfo.mobile
        }
        const res=await getAuthCode(3,sendType,address)
        if (!res.success) return this.$message.warning(res.message)
        if (res.success) this.$message.success(this.$t('alter.code_sended_email'))
      }
      if (kind==='unPhone'){
        this.isUnPhoneSent=false
        const timer=setInterval(()=>{
          this.unPhoneSeconds--
          if (this.unPhoneSeconds===0){
            this.unPhoneSeconds=60
            this.isUnPhoneSent=true
            clearInterval(timer)
          }
        },1000)
        const res=await getAuthCode(3,2,this.userInfo.mobile)
        if (!res.success) return this.$message.warning(res.message)
        if (res.success) this.$message.success(this.$t('alter.code_sended_phone'))
      }
      if (kind==='unEmail'){
        this.isUnEmailSent=false
        const timer=setInterval(()=>{
          this.unEmailSeconds--
          if (this.unEmailSeconds===0){
            this.unEmailSeconds=60
            this.isUnEmailSent=true
            clearInterval(timer)
          }
        },1000)
        const res=await getAuthCode(3,1,this.userInfo.username)
        if (!res.success) return this.$message.warning(res.message)
        if (res.success) this.$message.success(this.$t('alter.code_sended_email'))
      }
    },
    //  修改手机号
    handleChangePhone(){
      this.$refs.changePhoneFormRef.validate( async valid=>{
        console.log(valid)
        if (!valid) {
          if(!old_auth_code){
            return this.$message.error(this.$t('alter.input_old_code'))
          } else if (!newPhone) {
            return this.$message.error(this.$t('alter.input_new_phone'))
          } else if (!auth_code){
            return this.$message.error(this.$t('alter.input_new_code'))
          } else {
            return this.$message.error(this.$t('alter.input_phone_error'))
          }
        }
        const {newPhone,auth_code,oldPhone,old_auth_code}=this.changePhoneForm
        let params={}
        //已经绑定过手机号 userBoundPhone为true
        this.userBoundPhone ? params=[3,2,newPhone,auth_code,old_auth_code] : params=[3,2,newPhone,auth_code]
        var res=await bindPhoneOrEmail(...params)
        if (!res.success) return this.$message.error(res.message)
        const {data:userInfo}=await getUserInfo()
        localStorage.setItem('userInfo',JSON.stringify(userInfo))
        this.getUserInfo()
        if (this.userBoundPhone) this.$message.success(this.$t('alter.change_phone_success'))
        else this.$message.success(this.$t('alter.bind_phone_success'))
        this.changePhoneDialogVisible=false
      })
    },
    //  修改邮箱
    handleChangeEmail(){
      this.$refs.bindEmailFormRef.validate( async valid=>{
        if (!valid) return this.$message.error(this.$t('alter.input_email_error'))
        const {email,auth_code,oldEmail,old_auth_code}=this.bindEmailForm
        let params={}
        //已经绑定过邮箱 userBoundEmail为true
        this.userBoundEmail ? params=[3,1,email,auth_code,old_auth_code] : params=[3,1,email,auth_code]
        var res=await bindPhoneOrEmail(...params)
        if (!res.success) return this.$message.error(res.message)
        const {data:userInfo}=await getUserInfo()
        localStorage.setItem('userInfo',JSON.stringify(userInfo))
        this.getUserInfo()
        if (this.userBoundEmail) {
          // this.$message.success('更換郵箱成功 !')
          this.$message.success(this.$t('alter.change_email_success'))
          // localStorage.removeItem('token')
          // localStorage.removeItem('userInfo')
          // this.$router.replace('/login')
        }
        else {
          this.$message.success(this.$t('alter.bing_email_success'))
        }
        this.bindEmailDialogVisible=false
      })
    },
    //  修改登录密码
    handleChangePassword(){
      this.$refs.changePasswordFormRef.validate( async valid=>{
        if (!valid) return this.$message.error(this.$t('alter.input_right_info'))
        const {old_password,new_password,confirm_password}=this.changePasswordForm
        const {username,mobile}=this.userInfo
        let params=[]
        this.userBoundEmail ? params=[1,username,old_password,new_password] :params=[2,mobile,old_password,new_password]
        if (!checkNewPassword(new_password,confirm_password)) return this.$message.error(this.$t('alter.pwd_inconsistent'))
        const data=await changePassword(...params)
        if (!data.success) return this.$message.error(data.message)
        this.$message.success(this.$t('alter.pwd_alter_success_to_login'))
        this.changePasswordDialogVisible=false
        localStorage.removeItem('token')
        localStorage.removeItem('userInfo')
        this.$router.replace('/login')
      })
    },
    //  修改API信息
    handleChangeApi(){
      this.$refs.changeApiFormRef.validate( async (valid, object)=>{
        // if (!valid) return this.$message.error('请输入正确的API信息 !')
        if (!valid) return this.$message.error(object.key?object.key[0].message:object.secret?object.secret[0].message:object.auth_code?object.auth_code[0].message:'请输入正确的API信息 !')
        if (!this.userBoundEmail) return this.$message.warning(this.$t('alter.bind_email_first'))
        const {key,secret,auth_code}=this.changeApiForm
        const res=await changeUserApi(key,secret,auth_code,1)
        if (!res.success) return this.$message.error(res.message)
        await this.getApiInfo()
        await this.getRobotList()
        this.$message.success(this.$t('alter.change_api_info_success'))
        this.changeApiDialogVisible=false
      })
    },
    //  修改ok_API信息
    handleChangeOkApi(){
      this.$refs.changeOkApiFormRef.validate( async (valid, object)=>{
        if (!valid) return this.$message.error(object.key?object.key[0].message:object.secret?object.secret[0].message:object.okexPassphrase?object.okexPassphrase[0].message:object.auth_code?object.auth_code[0].message:'请输入正确的API信息 !')
        if (!this.userBoundEmail) return this.$message.warning(this.$t('alter.bind_email_first'))
        const {key,secret,auth_code,okexPassphrase}=this.changeOkApiForm
        const res=await changeOkApi(key,secret,auth_code,okexPassphrase,2)
        if (!res.success) return this.$message.error(res.message)
        await this.getOkApiInfo()
        await this.getRobotList()
        this.$message.success(this.$t('alter.change_api_info_success'))
        this.changeOkApiDialogVisible=false
      })
    },
    //获取用户的api信息
    async getApiInfo(){
      const {data:res}=await getUserApiInfo()
      this.apiInfo=res
    },
    // 获取ok_api信息
    async getOkApiInfo(){
      const {data:res}=await getOkApiInfo()
      this.okApiInfo=res
    },

    changeAndBindOkApi(){
      // this.$confirm('系統升級中，即將開啓！', {
      //   showConfirmButton: false,
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).catch(() => {
      //
      // });
      this.changeOkApiDialogVisible=true
    },

    //解绑api前的校驗
    beforeClearApi(item, num){
      if (item.state != 1){
        this.clearApi(item.robot_type, num)
      } else{
        this.$confirm(this.$t('alter.go_to_robot_close_robot'))
            .then(async _ =>{

            })
            .catch(_ =>{});
      }
    },
    //解绑api
    async clearApi(robot_type,num) {
      this.$confirm(this.$t('alter.are_you_sure_unbind_api'))
          .then(async _ => {
            const res = await ClearApi(num)
            if (res.success) {
              this.$message.success(this.$t('alter.unbind_success'))
              if (robot_type == 0){
                await this.getApiInfo()
              } else if(robot_type == 1){
                await this.getOkApiInfo();
              }
              await this.getRobotList()
            } else {
              this.$message.error(res.message)
            }
          })
          .catch(_ => {});
    },

    // 設置默認展示
    async setNo1(num){
      const res = await updateRobotSort(num)
      if (res){
        if (res.success){
          this.$message.success(this.$t('alter.set_priority_success'))
          await this.getRobotList();
        }else{
          this.$message.error(res.message)
        }
      }
    },

    // 获取机器人列表
    async getRobotList(){
      const res = await getRobotList();
      if(res){
        if (res.success){
          if(res.data){
            this.apiList = res.data

            for (let i=0; i< res.data.length; i++){
              if (res.data[i].robot_type == 0){
                this.bian_Item = res.data[i]
              } else if(res.data[i].robot_type == 1){
                this.okex_Item = res.data[i]
              }
            }

            for (let i = 0; i < this.apiList.length; i++) {
              if (this.apiList[i].robot_type == 1 && !this.okApiInfo.okex_api_key) {
                this.apiList.splice(i, 1)
              }
            }
            for (let i = 0; i < this.apiList.length; i++) {
              if (this.apiList[i].robot_type == 0 && !this.apiInfo.binance_api_key) {
                this.apiList.splice(i, 1)
              }
            }
          }
        }
      }
    },
    //解綁手機號
    handleUnPhone(e){
      if (e==1){
        this.$refs.unEmailFormRef.validate( async valid=>{
          if (!valid) return this.$message.warning(this.$t('alter.input_email_code')+' !')
          const res= await userUnEmailOrPhone(e,this.unEmailForm.auth_code)
          if (!res.success) return this.$message.error(res.message)
          this.$message.success(this.$t('alter.unbind_success'))
          this.unbindEmailDialogVisible=false
          const {data:userInfo}=await getUserInfo()
          localStorage.setItem('userInfo',JSON.stringify(userInfo))
          this.getUserInfo()
        })
      }
      else if (e==2){
        this.$refs.unPhoneFormRef.validate( async valid=>{
          if (!valid) return this.$message.warning(this.$t('alter.input_phone_code')+' !')
          const res= await userUnEmailOrPhone(e,this.unPhoneForm.auth_code)
          if (!res.success) return this.$message.error(res.message)
          this.$message.success(this.$t('alter.unbind_success')+' !')
          this.unbindPhoneDialogVisible=false
          const {data:userInfo}=await getUserInfo()
          localStorage.setItem('userInfo',JSON.stringify(userInfo))
          this.getUserInfo()
        })
      }
    },
    copy() {
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', this.$registered_address.okx_address());
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        this.$message.success(this.$t('rebate.copy_success'))
      }
      document.body.removeChild(input);
    },
    handleClosePhoneDialog(){
      this.changePhoneForm = {
        oldPhone:'',
        old_auth_code:'',
        newPhone:'',
        auth_code:''
      }
      this.isOldPhoneSent = true
      this.isPhoneSent = true
    },
    handleCloseEmailDialog(){
      this.bindEmailForm.old_auth_code = ''
      this.bindEmailForm.email = ''
      this.bindEmailForm.auth_code = ''
      this.isOldEmailSent = true
      this.isEmailSent = true
    },
    closebianDialog(){
      this.phoneApiSeconds = true
      this.changeOkApiForm.key = ''
      this.changeOkApiForm.secret = ''
      this.changeOkApiForm.okexPassphrase = ''
      this.changeOkApiForm.auth_code = ''
    },
    closeOkxDialog(){
      this.phoneApiSeconds = true
      this.changeOkApiForm.key = ''
      this.changeOkApiForm.secret = ''
      this.changeOkApiForm.okexPassphrase = ''
      this.changeOkApiForm.auth_code = ''
    }
  }
}
</script>

<style scoped>
.base-info .title{
  font-size: 16px;
  font-weight: 600;
  color: #1E2329;
  margin-bottom: 16px;
}
.base-info .base-info-body{
  border-bottom: 1px solid #EAECEF;
  padding: 24px 0 26px 0;
  position: relative;
}
.base-info .base-info-body:nth-child(4){
  border-bottom: none;
  padding: 24px 0 6px 0;
}
.base-info .base-info-body .left img{
  width: 26px;
  height: 26px;
  margin-right: 12px;
}
.base-info .base-info-body .left .title-sub{
  font-size: 16px;
  font-weight: 600;
  color: #1E2329;
  display: inline-block;
  vertical-align: top;
}
.base-info .base-info-body .left>div div{
  font-size: 14px;
  font-weight: 400;
  margin-top: 13px;
  margin-left: 40px;
  color: #72777E;
}
.base-info .base-info-body .right{
  position: absolute;
  top: 40px;
  right: 0;
}
/*api信息*/
.api-body .left>div>div>span:first-child{
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: #000613;
}
.api-body .left>div>div>span:nth-child(2){
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: #72777E;
  margin-left: 5px;
}
.api-body .left>div>div:nth-child(2){
  margin-top: 6px;
}
.api .base-info-body{
  border-bottom: none;
  padding: 24px 0 6px 0;
}
.api .base-info-body:nth-child(2){
  border-bottom: 1px solid #EAECEF;
  padding: 24px 0 26px 0;
}
/**********       验证码的布局     ******/
.el-form-item{
  position: relative;
}
.auth-code{
  position: absolute;
  top:40px;
  right: 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #8E9298;
}
.auth-code>div>span:first-child{
  font-size: 14px;
  font-weight: 400;
  color: #AB54DB;
  margin-right: 6px;
}

/*当屏幕尺寸小于700*/
@media screen and (max-width: 700px) {
  .base-info .base-info-body .right{
    position: static;
    margin-top: 16px;
    margin-left: 40px;
  }
  .api-body .left>div>div:first-child>span:nth-child(2){
    width: 100%;
    display: inline-block;
    margin-left: 0;
    word-wrap:break-word;
  }

  .exclusive_tip >>>.el-dialog{
    position: absolute;
    left: 50%;
    right: unset;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .exclusive_tip_content span{
    font-size: 14px;
  }
  .exclusive_tip_content a{
    font-size: 14px;
  }
  .exclusive_tip >>>.el-dialog__close{
    font-size: 23px;
  }
}
@media screen and (min-width: 700px) {
  .exclusive_tip >>>.el-dialog{
    position: absolute;
    left: unset;
    right: 60px;
    top: 10%;
    transform: unset;
    transition: all 0.8s;
  }

  .exclusive_tip_content span{
    font-size: 16px;
  }
  .exclusive_tip_content a{
    font-size: 16px;
  }
  .exclusive_tip >>>.el-dialog__close{
    font-size: 28px;
  }

  .exclusive_pc >>>.el-drawer__header{
    padding: 20px 0;
  }
}

.exclusive_bg{
  display: inline-block;
  background: #FA3D58;
  border: 2px solid #FFFFFF;
  border-radius: 25px 25px 25px 0px;
  width: 20px;
  height: 14px;
  line-height: 14px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: #FEFEFE !important;
}

.exclusive_no_bg{
  display: inline-block;
  background: #97979D;
  border: 2px solid #FFFFFF;
  border-radius: 25px 25px 25px 0px;
  width: 20px;
  height: 14px;
  line-height: 14px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: #FEFEFE !important;
}

.exclusive_tip >>>.el-dialog{
  border-radius: 24px;
}
.exclusive_tip >>>.el-dialog__body{
  padding: 10px 20px;
}

>>>.el-drawer__open .el-drawer.rtl {
  animation: rtl-drawer-in 0.6s 1ms;
}

.exclusive_pc >>>.el-drawer{
  position: absolute;
  width: 390px !important;
  height: 235px;
  top: 10%;
  border-radius: 24px;
  right: 20px;
  padding: 0 16px;
}

</style>
